.App {
  text-align: center;
}

.App-logo {
  width: 200px;
  height: 56px;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.Gallery-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

@media screen and (min-width: 1000px) {
  .Gallery-main {
    max-width: 700px;
  }
}

.Gallery-image {
  max-width: 100%;
  margin-bottom: 15px;
}
